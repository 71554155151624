import validate from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.17_better-sqlite3@11.8.1_db0@0.3.1__bff9a60d7267b9e416ca4a554d21448a/node_modules/nuxt/dist/pages/runtime/validate.js";
import is_45desktop_45global from "/opt/render/project/src/libs/nuxt-forgd-layer-core/middleware/is-desktop.global.ts";
import manifest_45route_45rule from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.17_better-sqlite3@11.8.1_db0@0.3.1__bff9a60d7267b9e416ca4a554d21448a/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  is_45desktop_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/auth/middleware/auth.ts"),
  guest: () => import("/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/auth/middleware/guest.ts")
}