
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as active_45rfqshCaMRP6hwWuAHmngBiiKDqUTOdTQmXJ2_453QpTeddMkoMeta } from "/opt/render/project/src/apps/liquidity.forgd.com/layers/rfq/pages/active-rfqs.vue?macro=true";
import { default as closed_45rfqs9Yfu4_x2zBb2gUVLJUlQev1H4AduftU7_450nkszh0my8Meta } from "/opt/render/project/src/apps/liquidity.forgd.com/layers/rfq/pages/closed-rfqs.vue?macro=true";
import { default as indexBshXCSeYdgv_457_rSic2cOyTNtx11ORqJaoHz8ICeAXkMeta } from "/opt/render/project/src/apps/liquidity.forgd.com/pages/login/index.vue?macro=true";
import { default as mobile_45gatekAyyeZfEnhxcqb1T3i53y4mVJQQcdZLXvzjfj4cDuugMeta } from "/opt/render/project/src/libs/nuxt-forgd-layer-core/pages/mobile-gate.vue?macro=true";
import { default as summaryx0jAS4Yr_BYTZFEG355Zk_45Ienx3ro8xuhVejvVXK8CMMeta } from "/opt/render/project/src/apps/liquidity.forgd.com/layers/rfq/pages/[rfqId]/summary.vue?macro=true";
import { default as indexnIe0ite5Zhb_DxZ7rqbrNl7os0T_nPX_IxD4Mn_459DL4Meta } from "/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/auth/pages/magic-link/index.vue?macro=true";
import { default as authorizationpns0R4C8nJaFWspe3xh9WdmzuEDPCAwqGwG_XHKwCz4Meta } from "/opt/render/project/src/apps/liquidity.forgd.com/pages/v2/authorization.vue?macro=true";
import { default as _91_46_46_46slug_93AqI5vzdMnbFJeIgoQHjUnn9AlfY5oNUjgbi6jXC6_d0Meta } from "/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/academy/pages/academy/[...slug].vue?macro=true";
import { default as depthZRy5zRQpbU0i7XzJlVtXm_45bb8R65sBQC76M8LCTOxxkMeta } from "/opt/render/project/src/apps/liquidity.forgd.com/layers/leaderboard/pages/leaderboards/depth.vue?macro=true";
import { default as indexS9EAOLRA43Auo9vIMmN4Ll6BuvM5TkbaCm0IkSgiwukMeta } from "/opt/render/project/src/apps/liquidity.forgd.com/layers/leaderboard/pages/leaderboards/index.vue?macro=true";
import { default as newH7rHlrGP_INqUi6yZB4vlG8lAgMXnMCM89Xd_45DOyFhIMeta } from "/opt/render/project/src/apps/liquidity.forgd.com/layers/engagements/pages/my-engagements/new.vue?macro=true";
import { default as indexrsMIpkuc76ev75_cRP5eIV90A33EjtXVDo6iHL1l53IMeta } from "/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/auth/pages/password/new/index.vue?macro=true";
import { default as indexPjjbCts0ywTi54ZqMwcoDLlNRZZsafOVsKBh4_452MEP8Meta } from "/opt/render/project/src/apps/liquidity.forgd.com/pages/set-password/index.vue?macro=true";
import { default as indexgQTfHNlJKHiUW_45SnfSkgGrdyM8TkcErG1Mdvki1lGL0Meta } from "/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/auth/pages/verify-email/index.vue?macro=true";
import { default as indexCecKR3D_454S2_gPaIm7BXluQxM77M5TH4GbNBdZ4vU4sMeta } from "/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/auth/pages/confirm-email/index.vue?macro=true";
import { default as leaderboard_45opt_45insp9ECDOZinTSqVRUvf6UOUVfM6nUWkBLUf5XoiB6pl_45IMeta } from "/opt/render/project/src/apps/liquidity.forgd.com/layers/leaderboard/pages/leaderboard-opt-ins.vue?macro=true";
import { default as spread4LVmRCbfWoB3X_49XWb0zS1_nxi56Hb6pDdeNKFM7bAMeta } from "/opt/render/project/src/apps/liquidity.forgd.com/layers/leaderboard/pages/leaderboards/spread.vue?macro=true";
import { default as volumexyPfCiJnYWOnxnoWUW8hFj_zUoCTS5o04B8H_brGCPkMeta } from "/opt/render/project/src/apps/liquidity.forgd.com/layers/leaderboard/pages/leaderboards/volume.vue?macro=true";
import { default as indexXzC_3k_45TTZ0bbWVcKTM8ihm3y1Dl2Qy4qwiQlVm8gxUMeta } from "/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/auth/pages/email-redirect/index.vue?macro=true";
import { default as indexfZxeowHvUd_Bo5FL_ZzrQo915yp8Pkjeaevy5znI8h8Meta } from "/opt/render/project/src/apps/liquidity.forgd.com/layers/engagements/pages/my-engagements/index.vue?macro=true";
import { default as indexVsJ3Y9CBWwhs_45cRYjB_45_SHjwMCnDMYytgncMMSJX2FAMeta } from "/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/auth/pages/page-not-found/index.vue?macro=true";
import { default as index3dyXAQxbkgylQA6DfyHAsw0Ek3Xn4ovpA2DLkuagWUUMeta } from "/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/auth/pages/reset-password/index.vue?macro=true";
import { default as indexWIGryPbaLoed4csmMnyPGFAVo0Mx4squsz1VsenpokYMeta } from "/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/auth/pages/forgot-password/index.vue?macro=true";
import { default as round_451_45outcomemy_RfmOe9O50cWlDD8kwGli5UAJRTKEUncYy94mXCXgMeta } from "/opt/render/project/src/apps/liquidity.forgd.com/layers/rfq/pages/[rfqId]/round-1-outcome.vue?macro=true";
import { default as round_452_45outcomefw1jZAcoV3Gc2zQ13ya6XbkRiMt4cMUHK_45kQxu1Brn0Meta } from "/opt/render/project/src/apps/liquidity.forgd.com/layers/rfq/pages/[rfqId]/round-2-outcome.vue?macro=true";
import { default as monitor_45active_45projectsdQ2YcR52o1_MtM7ZDc1LJmKHbqknZ5tgOTCHSM_45cuUQMeta } from "/opt/render/project/src/apps/liquidity.forgd.com/layers/monitor-active-projects/pages/monitor-active-projects.vue?macro=true";
import { default as round_451_45my_45quotesiPIfqp24fwrUgvAfBqxH3j4rO0sMpxlzYq7442_45cD2cMeta } from "/opt/render/project/src/apps/liquidity.forgd.com/layers/rfq/pages/[rfqId]/round-1-my-quotes.vue?macro=true";
import { default as round_452_45my_45quotesP1xS3wOUwj9qM45nUE1w98Tu0H0v4wg1YUPds69PYJYMeta } from "/opt/render/project/src/apps/liquidity.forgd.com/layers/rfq/pages/[rfqId]/round-2-my-quotes.vue?macro=true";
import { default as _91engagementId_93aqQRnpq34tWZs1A1EHnpWnBZVIvP6jFDhpH8mSHPEMsMeta } from "/opt/render/project/src/apps/liquidity.forgd.com/layers/engagements/pages/my-engagements/[engagementId].vue?macro=true";
import { default as component_45stubDy0d6CZp1O5H_45_cDo5I_45ufKwKV_ndDSE6L2N_cDP8l8Meta } from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.17_better-sqlite3@11.8.1_db0@0.3.1__bff9a60d7267b9e416ca4a554d21448a/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubDy0d6CZp1O5H_45_cDo5I_45ufKwKV_ndDSE6L2N_cDP8l8 } from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.17_better-sqlite3@11.8.1_db0@0.3.1__bff9a60d7267b9e416ca4a554d21448a/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "active-rfqs",
    path: "/active-rfqs",
    meta: active_45rfqshCaMRP6hwWuAHmngBiiKDqUTOdTQmXJ2_453QpTeddMkoMeta || {},
    component: () => import("/opt/render/project/src/apps/liquidity.forgd.com/layers/rfq/pages/active-rfqs.vue")
  },
  {
    name: "closed-rfqs",
    path: "/closed-rfqs",
    meta: closed_45rfqs9Yfu4_x2zBb2gUVLJUlQev1H4AduftU7_450nkszh0my8Meta || {},
    component: () => import("/opt/render/project/src/apps/liquidity.forgd.com/layers/rfq/pages/closed-rfqs.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: indexBshXCSeYdgv_457_rSic2cOyTNtx11ORqJaoHz8ICeAXkMeta || {},
    component: () => import("/opt/render/project/src/apps/liquidity.forgd.com/pages/login/index.vue")
  },
  {
    name: "mobile-gate",
    path: "/mobile-gate",
    meta: mobile_45gatekAyyeZfEnhxcqb1T3i53y4mVJQQcdZLXvzjfj4cDuugMeta || {},
    component: () => import("/opt/render/project/src/libs/nuxt-forgd-layer-core/pages/mobile-gate.vue")
  },
  {
    name: "rfqId-summary",
    path: "/:rfqId()/summary",
    meta: summaryx0jAS4Yr_BYTZFEG355Zk_45Ienx3ro8xuhVejvVXK8CMMeta || {},
    component: () => import("/opt/render/project/src/apps/liquidity.forgd.com/layers/rfq/pages/[rfqId]/summary.vue")
  },
  {
    name: "magic-link",
    path: "/magic-link",
    meta: indexnIe0ite5Zhb_DxZ7rqbrNl7os0T_nPX_IxD4Mn_459DL4Meta || {},
    component: () => import("/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/auth/pages/magic-link/index.vue")
  },
  {
    name: "v2-authorization",
    path: "/v2/authorization",
    meta: authorizationpns0R4C8nJaFWspe3xh9WdmzuEDPCAwqGwG_XHKwCz4Meta || {},
    component: () => import("/opt/render/project/src/apps/liquidity.forgd.com/pages/v2/authorization.vue")
  },
  {
    name: "academy-slug",
    path: "/academy/:slug(.*)*",
    meta: _91_46_46_46slug_93AqI5vzdMnbFJeIgoQHjUnn9AlfY5oNUjgbi6jXC6_d0Meta || {},
    component: () => import("/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/academy/pages/academy/[...slug].vue")
  },
  {
    name: "leaderboards-depth",
    path: "/leaderboards/depth",
    meta: depthZRy5zRQpbU0i7XzJlVtXm_45bb8R65sBQC76M8LCTOxxkMeta || {},
    component: () => import("/opt/render/project/src/apps/liquidity.forgd.com/layers/leaderboard/pages/leaderboards/depth.vue")
  },
  {
    name: "leaderboards",
    path: "/leaderboards",
    meta: indexS9EAOLRA43Auo9vIMmN4Ll6BuvM5TkbaCm0IkSgiwukMeta || {},
    component: () => import("/opt/render/project/src/apps/liquidity.forgd.com/layers/leaderboard/pages/leaderboards/index.vue")
  },
  {
    name: "my-engagements-new",
    path: "/my-engagements/new",
    meta: newH7rHlrGP_INqUi6yZB4vlG8lAgMXnMCM89Xd_45DOyFhIMeta || {},
    component: () => import("/opt/render/project/src/apps/liquidity.forgd.com/layers/engagements/pages/my-engagements/new.vue")
  },
  {
    name: "password-new",
    path: "/password/new",
    meta: indexrsMIpkuc76ev75_cRP5eIV90A33EjtXVDo6iHL1l53IMeta || {},
    component: () => import("/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/auth/pages/password/new/index.vue")
  },
  {
    name: "set-password",
    path: "/set-password",
    meta: indexPjjbCts0ywTi54ZqMwcoDLlNRZZsafOVsKBh4_452MEP8Meta || {},
    component: () => import("/opt/render/project/src/apps/liquidity.forgd.com/pages/set-password/index.vue")
  },
  {
    name: "verify-email",
    path: "/verify-email",
    meta: indexgQTfHNlJKHiUW_45SnfSkgGrdyM8TkcErG1Mdvki1lGL0Meta || {},
    component: () => import("/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/auth/pages/verify-email/index.vue")
  },
  {
    name: "confirm-email",
    path: "/confirm-email",
    meta: indexCecKR3D_454S2_gPaIm7BXluQxM77M5TH4GbNBdZ4vU4sMeta || {},
    component: () => import("/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/auth/pages/confirm-email/index.vue")
  },
  {
    name: "leaderboard-opt-ins",
    path: "/leaderboard-opt-ins",
    meta: leaderboard_45opt_45insp9ECDOZinTSqVRUvf6UOUVfM6nUWkBLUf5XoiB6pl_45IMeta || {},
    component: () => import("/opt/render/project/src/apps/liquidity.forgd.com/layers/leaderboard/pages/leaderboard-opt-ins.vue")
  },
  {
    name: "leaderboards-spread",
    path: "/leaderboards/spread",
    meta: spread4LVmRCbfWoB3X_49XWb0zS1_nxi56Hb6pDdeNKFM7bAMeta || {},
    component: () => import("/opt/render/project/src/apps/liquidity.forgd.com/layers/leaderboard/pages/leaderboards/spread.vue")
  },
  {
    name: "leaderboards-volume",
    path: "/leaderboards/volume",
    meta: volumexyPfCiJnYWOnxnoWUW8hFj_zUoCTS5o04B8H_brGCPkMeta || {},
    component: () => import("/opt/render/project/src/apps/liquidity.forgd.com/layers/leaderboard/pages/leaderboards/volume.vue")
  },
  {
    name: "email-redirect",
    path: "/email-redirect",
    meta: indexXzC_3k_45TTZ0bbWVcKTM8ihm3y1Dl2Qy4qwiQlVm8gxUMeta || {},
    component: () => import("/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/auth/pages/email-redirect/index.vue")
  },
  {
    name: "my-engagements",
    path: "/my-engagements",
    meta: indexfZxeowHvUd_Bo5FL_ZzrQo915yp8Pkjeaevy5znI8h8Meta || {},
    component: () => import("/opt/render/project/src/apps/liquidity.forgd.com/layers/engagements/pages/my-engagements/index.vue")
  },
  {
    name: "page-not-found",
    path: "/page-not-found",
    meta: indexVsJ3Y9CBWwhs_45cRYjB_45_SHjwMCnDMYytgncMMSJX2FAMeta || {},
    component: () => import("/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/auth/pages/page-not-found/index.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: index3dyXAQxbkgylQA6DfyHAsw0Ek3Xn4ovpA2DLkuagWUUMeta || {},
    component: () => import("/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/auth/pages/reset-password/index.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: indexWIGryPbaLoed4csmMnyPGFAVo0Mx4squsz1VsenpokYMeta || {},
    component: () => import("/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/auth/pages/forgot-password/index.vue")
  },
  {
    name: "rfqId-round-1-outcome",
    path: "/:rfqId()/round-1-outcome",
    meta: round_451_45outcomemy_RfmOe9O50cWlDD8kwGli5UAJRTKEUncYy94mXCXgMeta || {},
    component: () => import("/opt/render/project/src/apps/liquidity.forgd.com/layers/rfq/pages/[rfqId]/round-1-outcome.vue")
  },
  {
    name: "rfqId-round-2-outcome",
    path: "/:rfqId()/round-2-outcome",
    meta: round_452_45outcomefw1jZAcoV3Gc2zQ13ya6XbkRiMt4cMUHK_45kQxu1Brn0Meta || {},
    component: () => import("/opt/render/project/src/apps/liquidity.forgd.com/layers/rfq/pages/[rfqId]/round-2-outcome.vue")
  },
  {
    name: "monitor-active-projects",
    path: "/monitor-active-projects",
    meta: monitor_45active_45projectsdQ2YcR52o1_MtM7ZDc1LJmKHbqknZ5tgOTCHSM_45cuUQMeta || {},
    component: () => import("/opt/render/project/src/apps/liquidity.forgd.com/layers/monitor-active-projects/pages/monitor-active-projects.vue")
  },
  {
    name: "rfqId-round-1-my-quotes",
    path: "/:rfqId()/round-1-my-quotes",
    meta: round_451_45my_45quotesiPIfqp24fwrUgvAfBqxH3j4rO0sMpxlzYq7442_45cD2cMeta || {},
    component: () => import("/opt/render/project/src/apps/liquidity.forgd.com/layers/rfq/pages/[rfqId]/round-1-my-quotes.vue")
  },
  {
    name: "rfqId-round-2-my-quotes",
    path: "/:rfqId()/round-2-my-quotes",
    meta: round_452_45my_45quotesP1xS3wOUwj9qM45nUE1w98Tu0H0v4wg1YUPds69PYJYMeta || {},
    component: () => import("/opt/render/project/src/apps/liquidity.forgd.com/layers/rfq/pages/[rfqId]/round-2-my-quotes.vue")
  },
  {
    name: "my-engagements-engagementId",
    path: "/my-engagements/:engagementId()",
    meta: _91engagementId_93aqQRnpq34tWZs1A1EHnpWnBZVIvP6jFDhpH8mSHPEMsMeta || {},
    component: () => import("/opt/render/project/src/apps/liquidity.forgd.com/layers/engagements/pages/my-engagements/[engagementId].vue")
  },
  {
    name: component_45stubDy0d6CZp1O5H_45_cDo5I_45ufKwKV_ndDSE6L2N_cDP8l8Meta?.name,
    path: "/privacy-policy",
    component: component_45stubDy0d6CZp1O5H_45_cDo5I_45ufKwKV_ndDSE6L2N_cDP8l8
  },
  {
    name: component_45stubDy0d6CZp1O5H_45_cDo5I_45ufKwKV_ndDSE6L2N_cDP8l8Meta?.name,
    path: "/terms-of-use",
    component: component_45stubDy0d6CZp1O5H_45_cDo5I_45ufKwKV_ndDSE6L2N_cDP8l8
  },
  {
    name: component_45stubDy0d6CZp1O5H_45_cDo5I_45ufKwKV_ndDSE6L2N_cDP8l8Meta?.name,
    path: "/playbook",
    component: component_45stubDy0d6CZp1O5H_45_cDo5I_45ufKwKV_ndDSE6L2N_cDP8l8
  },
  {
    name: component_45stubDy0d6CZp1O5H_45_cDo5I_45ufKwKV_ndDSE6L2N_cDP8l8Meta?.name,
    path: "/",
    component: component_45stubDy0d6CZp1O5H_45_cDo5I_45ufKwKV_ndDSE6L2N_cDP8l8
  }
]