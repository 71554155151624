import { defineAsyncComponent } from 'vue'
export default {
  dashboard: defineAsyncComponent(() => import("/opt/render/project/src/apps/liquidity.forgd.com/layouts/dashboard.vue").then(m => m.default || m)),
  home: defineAsyncComponent(() => import("/opt/render/project/src/apps/liquidity.forgd.com/layouts/home.vue").then(m => m.default || m)),
  "core-dashboard": defineAsyncComponent(() => import("/opt/render/project/src/libs/nuxt-forgd-layer-core/layouts/core-dashboard.vue").then(m => m.default || m)),
  auth: defineAsyncComponent(() => import("/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/auth/layouts/auth.vue").then(m => m.default || m)),
  guest: defineAsyncComponent(() => import("/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/auth/layouts/guest.vue").then(m => m.default || m)),
  details: defineAsyncComponent(() => import("/opt/render/project/src/apps/liquidity.forgd.com/layers/rfq/layouts/details.vue").then(m => m.default || m)),
  engagements: defineAsyncComponent(() => import("/opt/render/project/src/apps/liquidity.forgd.com/layers/engagements/layouts/engagements.vue").then(m => m.default || m)),
  leaderboards: defineAsyncComponent(() => import("/opt/render/project/src/apps/liquidity.forgd.com/layers/leaderboard/layouts/leaderboards.vue").then(m => m.default || m))
}