const LiquidityConfigurationPaths = [
  '/my-engagements',
  '/leaderboard-opt-ins',
]

export default defineNuxtPlugin({
  name: 'liquidity-configuration',
  async setup() {
    const router = useRouter()

    // Add navigation guard for route changes
    router.beforeEach(async (to) => {
      const isLiquidityConfigurationPath = LiquidityConfigurationPaths.some(path => to.path.startsWith(path))
      if (!isLiquidityConfigurationPath) {
        return
      }

      const isFeatureEnabled = await useFeatureFlag('liquidity-configuration')
      if (!isFeatureEnabled) {
        return navigateTo('/active-rfqs')
      }
    })
  },
})
